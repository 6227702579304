<template>
  <b-container class="mt-5 w-50">
    <div class="text-center">
      <img
        src="../../assets/logo.png"
        alt="Happy Jungle Logo"
        class="img-fluid w-50"
      />
    </div>
    <LoginForm :loading="isLoading" @submit="onSubmit" />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoginForm from '@/components/Auth/LoginForm'

export default {
  name: 'LoginView',
  components: {
    LoginForm
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('store', ['isEmployer', 'isShopping'])
  },
  methods: {
    ...mapActions('auth', ['authenticate']),
    async onSubmit(credentials) {
      try {
        this.isLoading = true
        await this.authenticate(credentials)

        const name = this.isShopping
          ? 'ReportsIndex'
          : this.isEmployer
          ? 'CheckoutsIndex'
          : 'HomeIndex'
        this.$router.push({ name })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
