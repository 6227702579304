<template>
  <fieldset :disabled="loading">
    <b-form novalidate @submit.prevent="handleSubmit">
      <b-form-group label="Usuário" label-for="email">
        <b-form-input
          id="email"
          v-model="form.email"
          :state="validation.email"
        />
        <b-form-invalid-feedback v-if="$v.form.email.$invalid">
          Informe um e-mail válido
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Senha" label-for="password">
        <b-form-input
          id="password"
          v-model="form.password"
          type="password"
          :state="validation.password"
        />
        <b-form-invalid-feedback v-if="$v.form.password.$invalid">
          Informe a sua senha
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex flex-wrap align-items-center justify-content-between">
        <b-button variant="success" type="submit">
          <span class="fas fa-sign-in-alt" /> {{ label }}
        </b-button>
        <router-link
          :to="{ name: 'ResetPassword' }"
          class="text-decoration-none"
        >
          <span class="fas fa-envelope mx-1" /> Esqueceu a senha?
        </router-link>
      </div>
    </b-form>
  </fieldset>
</template>

<script>
import withFormValidation from '@/mixins/withFormValidation'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'LoginForm',
  mixins: [
    withFormValidation({
      fields: ['email', 'password']
    })
  ],
  props: {
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  validations: {
    form: {
      email: { required },
      password: { required }
    }
  },
  computed: {
    label() {
      return this.loading ? 'Aguarde...' : 'Entrar'
    }
  },
  watch: {
    form(value) {
      this.$emit('input', value)
    }
  }
}
</script>
